import $ from 'jquery';
import 'owl.carousel';
import 'simplebar';
import './base/polyfills';
import WOW from './components/wow.min';

/**
 * Import common scripts
 */
import './common';

/**
 * Import blocks
 */
import './blocks/reviews-widget';
import './blocks/text-block--advantages';
import './blocks/header';
import './blocks/quiz';

$(document).ready(() => {
    const str = `window location is ${window.location}`;
    console.log(str);

// Инициализация WOW js
    new WOW().init();


});

