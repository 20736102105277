import $ from 'jquery';
import scrollComponent from '../base/scroll.component';
import resizeComponent from '../base/resize.component';

/**
 * HEADER stiky menu
 */


(() => {
    let $body = $('body');
    let $headerFixed = $('.js-header-fixed');
    let headerFixedOffsetTop = 90; // offset height on desktop
    let headerHeight = $headerFixed.outerHeight();

    let checkIsFixed = () => {
        return scrollComponent.current() >= headerFixedOffsetTop;
    };

    let changeState = () => {
        if (checkIsFixed()) {
            $headerFixed.addClass('is-fixed');
            $body.css({'padding-top':`${headerHeight}px`});
        } else {
            $headerFixed.removeClass('is-fixed');
            $body.css({'padding-top':`${0}px`});
        }
    };

    // if (window.innerWidth > 981) { 
        window.addEventListener('load', () => {
            // checkOfsset();
            changeState();
        });
        window.addEventListener('scroll', changeState);
        window.addEventListener('resize', () => {
            // checkOfsset();
            changeState();
        });          
    // } else {
    //     $headerFixed.removeClass('is-fixed');
    //     $body.css({'padding-top':`${0}px`});        
    // }

    // changeState();
    // window.addEventListener('scroll', changeState);
    // window.addEventListener('resize', () => {
    //     // checkOfsset();
    //     changeState();
    // });         
    resizeComponent.addMediaQuery({
        min: 0,    // начало интервала
        max: 980, // конец интервала
        onEnter: function(){
            $headerFixed.removeClass('is-fixed');
            $body.css({'padding-top':`${0}px`});
        },        
        onExit: function(){
            changeState();
        }
    });    


})();


/**
 * Header menu
 */

$(document).ready(() => {
    (() => {
        let links = document.querySelectorAll('.main-menu a');
        links.forEach(function(el){
            el.addEventListener('click', function(e){
                e.preventDefault();
                let elId = e.target.getAttribute("href");
                let offset = document.querySelector(elId).offsetTop;
                $("body:not(:animated)").animate({ scrollTop: offset }, 500);
                $("html").animate({ scrollTop: offset }, 500);
            });
        })
    })();
});


/**
 * Header menu mobile
 */

$('body').on('click','.menu-mobile__item-name',function(e){
    e.preventDefault();
    setTimeout(() => {
        let anchor = $(this).attr('href').replace('#','');
        let scroll = $('#'+anchor).offset().top - 60;
        $('html, body').animate({ scrollTop: scroll }, 500);
        $('.menu-mobile__close-btn').click();
    }, 300)
    return false
});

/**
 * Phone mobile
 */
(() => {
    const phones = document.querySelectorAll('.js-header-phone');
    if (phones && phones.length) {
        const toggleHandler = (event, self) => {
            const toggle = event.target;
            if (self.classList.contains('is-expanded')) {
                self.classList.remove('is-expanded');
                toggle.classList.remove('is-active');
            } else {
                self.classList.add('is-expanded');
                toggle.classList.add('is-active');
            }
            event.preventDefault();
        };
        phones.forEach((self) => {
            const toggle = self.querySelector('[data-toggle]');
            if (toggle) {
                toggle.addEventListener('click', event => toggleHandler(event, self));
                toggle.addEventListener('touchstart', event => toggleHandler(event, self));
            }
        });
    }
})();