import $ from 'jquery';
import 'inputmask/dist/jquery.inputmask.bundle';
import App from "../common/app";

App.onAjaxStream.push(() => {
    if ('undefined' !== typeof $.fn.inputmask) {
        $('body').find('input.js-inputmask-phone').inputmask({"mask": "+7 (999) 999-99-99", showMaskOnHover :  false});
    }
});

$(document).ready(function(){
    $('body').find('input.js-inputmask-phone').inputmask({"mask": "+7 (999) 999-99-99", showMaskOnHover :  false});
});


