import scrollComponent from '../base/scroll.component';

const scrollTopModule = (() => {
    const self = {
        btn: null,
        speed: 500,
        offsetTop: 105,
    };

    const bindButton = (selector, callback) => {
        self.btn = document.querySelector(selector);
        if (self.btn) {
            callback();
        }
    };

    const checkScroll = () => {
        if (scrollComponent.current() > self.offsetTop) {
            self.btn.style.opacity = '1';
            self.btn.style.pointerEvents = 'auto';
        } else {
            self.btn.style.opacity = '0';
            self.btn.style.pointerEvents = 'none';
        }
    };

    const addHandlerScroll = () => {
        window.addEventListener('scroll', () => {
            checkScroll();
        });
    };

    const addHandlerClick = () => {
        self.btn.addEventListener('click', () => {
            $('body:not(:animated)').animate({ scrollTop: 0 }, self.speed);
            $('html').animate({ scrollTop: 0 }, self.speed);
            return false;
        });
    };

    return Object.freeze({
        init(selector, options) {
            bindButton(selector, () => {
                if (options.speed) { self.speed = options.speed; }
                if (options.offsetTop) { self.offsetTop = options.offsetTop; }
                checkScroll();
                addHandlerScroll();
                addHandlerClick();
            });
        },
    });
})();

export default scrollTopModule;
