/**
 * Компонент для работы со скролом
 * @method {{ current, direction, enable, disable, moveTo }}
 */
const scrollComponent = (() => {
    const self = {
        elsTransform: document.querySelectorAll('.l-wrapper, .l-footer'),
        current: 0,
        previous: 0,
        backup: 0,
        direction: null,
    };

    // =================================================================================================================

    const checkScrollValue = () => {
        self.previous = self.current;
        // self.current = document.documentElement.scrollTop || window.pageYOffset || window.scrollY;
        self.current = window.pageYOffset;
        self.direction = (self.current >= self.previous) ? 'down' : 'up';
    };

    const scrollJumpTo = (position) => {
        window.scrollTo(0, position);
    };

    const saveCurrentScrollValue = () => {
        // self.backup = document.documentElement.scrollTop || window.pageYOffset || window.scrollY;
        self.backup = window.pageYOffset;
    };

    // =================================================================================================================

    /**
     * animate scroll to new position
     * @param {number} - new scroll position
     * @param {number} - time
     */
    const scrollMoveTo = (position, speed) => { // TODO: remove jquery
        $('body:not(:animated)').animate({ scrollTop: position }, speed);
        $('html').animate({ scrollTop: position }, speed);
    };

    /**
     * enable scroll
     */
    const enableScroll = () => {
        self.elsTransform.forEach((item) => {
            item.style.transform = 'none';
        });
        document.body.style.overflowY = '';
        document.body.style.position = '';
        scrollJumpTo(self.backup);
    };

    /**
     * disable scroll
     */
    const disableScroll = () => {
        saveCurrentScrollValue();
        self.elsTransform.forEach((item) => {
            item.style.transform = `translateY(${-self.backup}px)`;
        });
        document.body.style.overflowY = 'hidden';
        document.body.style.position = 'fixed';
    };

    /**
     * get current scroll value
     * @returns {number} current value
     */
    const getCurrentScrollValue = () => self.current;

    /**
     * get current scroll direction
     * @returns {*} 'down'|'up'
     */
    const getCurrentScrollDirection = () => self.direction;


    // =================================================================================================================

    checkScrollValue();
    window.addEventListener('scroll', checkScrollValue);

    // =================================================================================================================

    return Object.freeze({
        current: getCurrentScrollValue,
        direction: getCurrentScrollDirection,
        enable: enableScroll,
        disable: disableScroll,
        moveTo: scrollMoveTo,
    });
})();


export default scrollComponent;
