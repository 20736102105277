import resizeComponent from '../base/resize.component';
import App from '../common/app';

const $reviewsWidget = $('.js-reviews-widget');
if ($reviewsWidget.length > 0) {
    ((_root) => {
        const self = {
            root: _root,
            carousel: _root.find('.js-slider'),
        };

        if (self.carousel.find('.reviews-widget__item').length > 0) {
            self.carousel.addClass('owl-carousel');

            self.carousel.owlCarousel({
                rtl: App.isRtl,
                nav: true,
                dots: true,
                loop: false,
                margin: 50,
                items: 4,
                responsive: {
                    0: {
                        items: 1,
                        nav: false,
                    },
                    580: { items: 2 },
                    820: {
                        items: 3,
                        nav: true,
                    },
                    1270: { items: 4 },
                },
                onInitialized: () => {
                    self.root.addClass('is-initialized');
                },
            });
        }
    })($reviewsWidget);
}
