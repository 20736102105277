import jQuery from 'jquery';
import App from "../common/app";

App.onAjaxStream.push(() => {
    $(function(){
        var next = $('.js-btn-next');
        var progress = $('.quiz__progress-bar');
        var stepsCount = $('.js-quiz-content').length;
        var currentStep = 1;

        function calculate(current, total) {
            return (current / total) * 100
        }

        progress.width(calculate(currentStep, stepsCount) + '%');

        next.on('click', function(){
            var currentSection = $(this).parents('.js-quiz-content');
            currentSection.removeClass('is-active');
            currentSection.next().addClass('is-active');
            progress.width(calculate(++currentStep, stepsCount) + '%')
        });
    });
});


$(function(){
    var next = $('.js-btn-next');
    var progress = $('.quiz__progress-bar');
    var stepsCount = $('.js-quiz-content').length;
    var currentStep = 1;

    function calculate(current, total) {
        return (current / total) * 100
    }

    progress.width(calculate(currentStep, stepsCount) + '%');

    next.on('click', function(){
        var currentSection = $(this).parents('.js-quiz-content');
        currentSection.removeClass('is-active');
        currentSection.next().addClass('is-active');
        progress.width(calculate(++currentStep, stepsCount) + '%')
    });
});
