(()=>{
    /********************************************************************
     * Config
     *******************************************************************/
    const config = {
        // Current language
        lang: 'ru',

        // Translations of errors
        translations: {
            ru: {
                field: {
                    empty: `Значение не должно быть пустым.`,
                    format: {
                        email: `Некорректный формат электронной почты`,
                        phone: `Некорретный формат телефона`,
                    }
                },
                common: {
                    empty: `Поле не должно быть пустым`
                },
                status: {
                    success: `Ваша заявка успешно отправлена! `,
                    message: 'В ближайшее время с вами свяжется наш сотрудник для подтверждения рейса. По всем вопросам вы можете звонить по телефонам: <strong>8 (919) 014-60-62</strong> ,  <strong>8 (920) 901-73-96</strong>',
                    error: `Возникла ошибка. Сообщение не было отправлено`,
                }
            },
            en: {
                field: {
                    empty: `This value should not be blank.`,
                    format: {
                        email: `Invalid email format`,
                        phone: `Invalid phone number format`,
                    }
                },
                common: {
                    empty: `Email or phone must be filled`
                },
                status: {
                    success: `Your message has been successfully sent`,
                    error: `An error occurred. Message hasn't been sent`,
                }
            },
            ar: {
                field: {
                    empty: `هذه القيمة يجب الا تكون فارغة.`,
                    format: {
                        email: `هذه القيمة ليست صالحة.`,
                        phone: `هذه القيمة ليست صالحة.`,
                    }
                },
                common: {
                    empty: `Email or phone must be filled`
                },
                status: {
                    success: `Your message has been successfully sent`,
                    error: `An error occurred. Message hasn't been sent`,
                }
            },
        },

        htmlTemplateSuccess: (status, text) => {
            return `<div class="form-success">
    <img class="form-success__icon" src="/assets/images/form-success.svg" alt="success" title="success">
    <p class="form-success__title">Ваша заявка успешно отправлена!</p>
    <p class="form-success__msg">В ближайшее время с вами свяжется наш сотрудник для подтверждения рейса. По всем вопросам вы можете звонить по телефонам: <strong>8 (919) 014-60-62</strong> ,  <strong>8 (920) 901-73-96</strong></p>
</div>`;
        }
    };

    window.config = config;


    /********************************************************************
     * Utils
     *******************************************************************/

    /**
     * Tracking goals
     * @param {string} goalName
     * @param {string} goalCategory
     */
    function goalDone (goalName, goalCategory) {
        // Enable/Disable tracking services
        const yaCounterID = '';

        if (typeof window[`yaCounter${yaCounterID}`] !== 'undefined') { window[`yaCounter${yaCounterID}`].reachGoal(goalName, () => {}); }
        if (typeof ga !== 'undefined') { window.ga('send', 'event', goalCategory, goalName); }
        if (typeof gtag !== 'undefined') { window.gtag('event', goalName, { event_category: goalCategory }); }
        if (typeof fbq !== 'undefined') { window.fbq('track', goalName, { }); }
        console.log(`Goal done. name: ${goalName}, category: ${goalCategory}`);
    };


    /**
     * Делаем всякую поебистику с формами
     */
    class Form{
        constructor(root, config){
            this.form = root;
            this.url = root.getAttribute('action');
            this.fields = [...root.querySelectorAll('[data-field]')];
            this.formError = root.querySelector('[data-error]');
            this.btn = root.querySelector('button[type="submit"]');
            this.errors = config.translations[config.lang].field;
            this.htmlTemplateError = (text) => `<ul><li>${text}</li></ul>`;
            this.init(this.formError);
        }

        init(config) {
            let form = this.form;
            let that = this;
            form.addEventListener('submit', (e) => {
                e.preventDefault();

                // логика google-рекапчи v3
                let tk = '';

                grecaptcha.ready(function() {
                    grecaptcha.execute('6LexpZIgAAAAALRi5qmFrhXhppmXRTdB0Ewh78y_', {action: 'submit'}).then(function(token) {
                        tk = token;
                        form.querySelector('input[name="token"]').value = tk;
        
                        const recaptchaData = new URLSearchParams();
                        for (const pair of new FormData(form)) {
                            recaptchaData.append(pair[0], pair[1]);
                        }
        
                        fetch('recaptcha.php', {
                            method: 'post',
                            body: recaptchaData,
                        })
                        .then(response => response.json())
                        .then(result => {
                            if (result['om_score'] >= 0.5) {
                                console.log('Человек')

                                // логика отправки формы
                                const data = that.createData();
                                if (that.validateForm(data)) {
                                    console.log(data);
                                    that.sendRequest(that.url, data);
                                }
                            } else {
                                console.log('Бот')
                            }
                        });
                    });
                });
            });

            document.addEventListener('formSend', (e) => {
                if( form.dataset.send === 'true') {
                    if( e.detail.status ) {
                        form.outerHTML = window.config.htmlTemplateSuccess(e.detail.status, window.config.translations[window.config.lang].status.success);
                    } else {
                        form.outerHTML = window.config.htmlTemplateSuccess(e.detail.status, window.config.translations[window.config.lang].status.error);
                    }
                }
            });
        }

        validateForm(data) {
            let isValid = true;
            let empty = (typeof data.phone !== undefined && data.phone.length > 0) || (typeof data.email !== undefined && data.email.length > 0);

            (empty === false) ? this.formError.innerHTML = this.htmlTemplateError(config.translations[config.lang].common.empty) : '';

            this.fields.forEach( (item) => {
                const inputs = [...item.querySelectorAll('[data-input-type]')];
                inputs.forEach( (input) => {
                    const type = input.dataset.inputType;
                    const checkResult = validateField(input.value, type, this.errors, empty);

                    if (checkResult.isValid === false){
                        isValid = false;
                        let error = item.querySelector('[data-error]');
                        (error) ? error.innerHTML = this.htmlTemplateError(checkResult.error) : '';
                    }
                });
            });

            return isValid;
        }

        createData() {
            let result = {};

            this.fields.forEach( (item) => {
                const inputs = item.querySelectorAll('[data-input-type]');
                inputs.forEach( (input) => {
                    const type = input.dataset.inputType;

                    if (input.type === 'radio' && input.checked)
                    {
                        result[type] = input.value;
                    }

                    if (input.type !== 'radio')
                    {
                        result[type] = input.value;
                    }
                });
            });

            return result;
        };

        sendRequest(url, data) {
            const params = (() => {
                let result = '';
                for (let key in data) {
                    result += `&${key}=${data[key]}`;
                };
                result = result.substr(1);
                return result;
            })();

            let form = this.form;
            form.dataset.send = true;

            sendPost(url, params).then(function(json) {
                const res = JSON.parse(json);
                const detail = {
                    form: form,
                    status: res.status
                }
                console.log(res);
                document.dispatchEvent( new CustomEvent("formSend", {detail}));
            }, function(error) {
                console.log(error);
            });
        }
    }


    /**
     * This function checks the value validity of field type
     * @param {*} value field value
     * @param {*} type field type
     * @param {*} errors translations of errors
     * @param {*} empty can be empty
     */
    function validateField (value, type, errors, empty) {
        let isValid = false;
        let error = '';

        switch(type) {
            case 'title': {
                isValid = (!value.length);
                break;
            }
            case 'email': {
                if (empty > 0 && value === '') {
                    isValid = true;
                    break;
                }
                const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                isValid = reg.test(value);
                (!isValid) ? error = errors.format.email : '';
                break;
            }
            case 'phone': {
                // const reg = /(^(?!\+.*\(.*\).*\-\-.*$)(?!\+.*\(.*\).*\-$)(\+[0-9]{1,3}\([0-9]{1,3}\)[0-9]{1}([-0-9]{0,8})?([0-9]{0,1})?)$)|(^[0-9]{1,4}$)/;
                if (empty > 0 && value === '') {
                    isValid = true;
                    break;
                }
                const reg = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
                isValid = reg.test(value);
                (!isValid) ? error = errors.format.phone : '';
                break;
            }
            case 'name':
            case 'Заказать автобус':
            case 'Сколько вам нужно посадочных мест?':
            case 'Куда ехать':
            case 'Время и дата отправления':
            case 'Укажите имя контактного лица':
            case 'Имя':
            case 'Текст сообщения':
            {
                isValid = (value.length);
                break;
            }
            default: {
                isValid = true;
            }
        }

        return {
            isValid,
            error
        };
    };


    /**
     * Send POST requestues
     * @param {*} url
     * @param {*} requestuestBody
     */
    function sendPost(url, requestuestBody) {
        return new Promise(function(succeed, fail) {
            var request = new XMLHttpRequest();
            request.open("POST", url, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            request.addEventListener("load", function() {
                if (request.status < 400)
                    succeed(request.responseText);
                else
                    fail(new Error("Request failed: " + request.statusText));
            });
            request.addEventListener("error", function() {
                fail(new Error("Network error"));
            });
            request.send(requestuestBody);
        });
    };

    /********************************************************************
     * Initializing
     *******************************************************************/

    // костыль, необращаем внимания, прохдим мимо
    [...document.querySelectorAll('[data-input-type="title"]')].forEach((item) => {
        item.value = '';
    });

    // Forms
    [...document.querySelectorAll('.js-ajax')].forEach( (item) => {
        const form = new Form(item, config);
    });

    // Goals
    document.querySelectorAll('a[href^="tel:"]').forEach( (item) => {
        item.addEventListener('click', () => goalDone('Click on Phone', 'Clicks'));
    });
    document.querySelectorAll('a[href^="mailto:"]').forEach( (item) => {
        item.addEventListener('click', () => goalDone('Click on Email', 'Clicks'));
    });
    document.querySelectorAll('a[href^="https://api.whatsapp.com/"]').forEach( (item) => {
        item.addEventListener('click', () => goalDone('Click on WhatsApp', 'Clicks'));
    });
    document.addEventListener('formSend', (e) => {
        if( e.detail.status ) {
            goalDone(e.detail.form.dataset.goalName, e.detail.form.dataset.goalCategory);
        } else {
            console.warn('Произошла ошибка вовремя отпавки формы');
        }
    });

})();
