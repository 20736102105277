import $ from 'jquery';

// import resizeComponent from './base/resize.component';

import { loadScript } from './base/load.component';

import App from './common/app';

// import './components/blind-version/blind';
import './components/tabs.jquery';
import './components/datepicker';
import './components/timepicker';
// import './components/wow.min';
import './components/animateNumber.min';

// import './common/form';

import './common/landing';
import './common/inputmask';
import './common/novalidate';

import './common/listeners';

import './common/goals';
// import './common/cart-widget';
// import './common/checkout';


/**
 *  Кнопка скролящая наверх страницы
 */
import scrollTopModule from './components/scroll-top.module';
scrollTopModule.init('.js-scroll-top', { speed: 500, offsetTop: 105 });


/**
 * Lazy load for images
 */
import lazyImageComponent from './base/lazy-images.component';
document.addEventListener('DOMContentLoaded', () => {
    lazyImageComponent.add([].slice.call(document.querySelectorAll('img.js-img-lazy')));
});


/**
 * Обёртка над фотосвайпом
 */
import photoswipeWrapper from './components/photoswipe.wrapper';
document.addEventListener('DOMContentLoaded', () => {
    photoswipeWrapper.init('.js-photoswipe-gallery', 'a:not(.no-photoswipe)');
});


/**
 * Вставка видео
 */
import embedVideoModule from './components/embed-video.module';
document.addEventListener('DOMContentLoaded', () => {
    embedVideoModule.init('.js-embed-video');
});


/**
 * Менюшка для мобильника
 */
import MenuMobile from './components/menu-mobile.component';
document.addEventListener('DOMContentLoaded', () => {
    new MenuMobile(document.querySelector('.js-menu-mobile'), {
        text: {
            rootTitle: 'Меню',
        },
    });
});

/**
 *  Скрытие пунктов меню, если они не помещаются
 */
import MenuSmart from './components/menu-smart.component';
document.addEventListener('DOMContentLoaded', () => {
    new MenuSmart(document.querySelector('.js-menu-smart'));
});


/**
 * Всплывающие окна с аяксом
 */
// import './components/modal-ajax.jquery';
// (function initModalAjax() {
//     const init = ((context) => {
//         const $context = $(context || 'body');
//         $context.find('.js-ajax:not(form)').modalAjax();
//     })();
//     App.onAjaxStream.push(init);
// }());


/**
 *  Генерируемые всплывающие окна
 */
// import './components/modal-html.jquery';
// (function initModalHtml() {
//     const init = ((context) => {
//         const $context = $(context || 'body');
//         $context.find('.js-modal').modalHtml();
//     })();
//     App.onAjaxStream.push(init);
// }());


/**
 * Add scrollbar for table on mobile
 */
import Tables from './components/tables';
Tables.addMobileView('table');

/**
 * Highslide
 */
import hs from '../vendor/highslide/highslide-full';
App.initComponent('.bigpic', (el) => {
    const link = el.parentNode;
    if (link.nodeName === 'A') {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            hs.expand(link);
            return false;
        });
    }
});

/**
 * Parallax on scroll
 */
// import ParallaxOnScroll from './components/parallax-on-scroll';
// document.addEventListener('DOMContentLoaded', () => {
//     document.querySelectorAll('.js-parallax-on-scroll').forEach((element) => {
//         new ParallaxOnScroll(element);
//     });
// });

// $(document).ready(() => {
//     new WOW().init();
// });

$('body').on('click', '.js-modal-close',function(e){
    e.preventDefault();
    $('.modal').removeClass('opened');

    $('body').removeClass('open-modal');

    return false;
});
$('.js-open-modal').on('click',function(e){
    e.preventDefault();
    var name = $(this).data('class');
    var carTitle = $(this).closest('.js-product-list-item').find('.js-title').html();

    $('body').addClass('open-modal');

    if (name == '-order') {
        $('.modal.-order').addClass('opened');

    } else if (name == '-send-enquiry') {
        $('.modal.-send-enquiry').find('.js-message').val('Я хотел бы заказать ' + carTitle);
        $('.modal.-send-enquiry').addClass('opened');
    }

    return false;
});

(function () {
    if($('.advantages__carousel').length){
        var $elems = $('.spincrement');
        var top = $('.advantages').offset().top;
        $(window).on('scroll', function () {
            if($elems.length && $(document).scrollTop() + $(window).outerHeight() >= top){
                $elems.each(function () {
                    var $this = $(this);
                    if(!$this.hasClass('spincrement-end')){
                        var number = $this.text();
                        $this.text('0');
                        $this.animateNumber({
                            number: number,
                            duration: 10000
                        });
                        $this.addClass('spincrement-end');
                    }
                });
            }
        });
    }

})();
