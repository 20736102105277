import $ from 'jquery';
import App from '../common/app';

const $advantages = $('.js-advantages');
if ($advantages.length > 0) {
    ((_root) => {
        const self = {
            carousel: _root.find('.owl-carousel'),
        };

        self.carousel.owlCarousel({
            rtl: App.isRtl,
            nav: false,
            dots: true,
            loop: false,
            items: 5,
            responsive: {
                0: { items: 1 },
                480: { items: 2 },
                768: { items: 3 },
                1024: { items: 4 },
                1200: { items: 5 }
            },
        });
    })($advantages);
}
